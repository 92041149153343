/**
 * This middleware redirects logged-in users away from pages like login, register etc
 */

export default defineNuxtRouteMiddleware(() => {
  const auth = useAuth()
  if (auth.loggedIn) {
    useAuth().redirectAuthenticatedHome()
  }
})
